import _ from 'lodash';

const map = {
    admin: 'Administration',
    mediateur: 'Médiateur',
    assurance: 'Assurance',
    parametrage: 'Paramétrage',
    dateDebutDemande: 'Demande Faite Après',
    dateFinDemande: 'Demande Faite Avant',
    noResult: 'Aucun Résultat',
    listeFichierJoint: 'Liste Fichier Joint',
    ownerType: 'Owner Type',
    lastName: 'Last Name',
    montantReclamation: 'Montant Reclamation',
    natureLitige: 'Nature Litige',
    dateSinistre: 'Date Sinistre',
    libelle: 'Libelle',
    demandeur: 'Demandeur',
    login: 'Login',
    ownerId: 'Owner Id',
    demande: 'Demande',
    numeroPolice: 'Numero Police',
    typeDemandeur: 'Type Demandeur',
    password: 'Password',
    file: 'File',
    lien: 'Lien',
    imageUrl: 'Image Url',
    assure: 'Assure',
    nom: 'Nom',
    prenom: 'Prenom',
    numeroDemande: 'Numéro Demande',
    dateDemande: 'Date Demande',
    commentaire: 'Commentaire',
    email: 'Email',
    key: 'Key',
    order: 'Order',
    etatDemande: 'Etat Demande',
    civilite: 'Civilite',
    resultatDemande: 'Resultat Demande',
    natureSinistre: 'Nature Sinistre',
    objetLitige: 'Objet Litige',
    ville: 'Ville',
    dateEtat: 'Date Etat',
    dateModification: 'Date Modification',
    telephone: 'Telephone',
    typeLien: 'Type Lien',
    authorities: 'Authorities',
    numeroSinistre: 'Numero Sinistre',
    firstName: 'First Name',
    souscripteur: 'Souscripteur',
    compagnie: 'Compagnie',
    dateSouscription: 'Date Souscription',
    branche: 'Branche',
    adresse: 'Adresse',
    descriptionLitige: 'Description Litige',
    'user.liste': 'Users',
    'user.info': 'User Info',
    'etatDemandeHist.liste': 'Etat Demande Hists',
    'etatDemandeHist.info': 'Etat Demande Hist Info',
    'alerte.liste': 'Alertes',
    'alerte.info': 'Alerte Info',
    'media.liste': 'Medias',
    'media.info': 'Media Info',
    'etatDemande.liste': 'Etat Demandes',
    'etatDemande.info': 'Etat Demande Info',
    'objetLitige.liste': 'Objet Litiges',
    'objetLitige.info': 'Objet Litige Info',
    'natureLitige.liste': 'Nature Litiges',
    'natureLitige.info': 'Nature Litige Info',
    'compagnie.liste': 'Compagnies',
    'compagnie.info': 'Compagnie Info',
    'branche.liste': 'Branches',
    'branche.info': 'Branche Info',
    'relance.liste': 'Relances',
    'relance.info': 'Relance Info',
    'demande.liste': 'Demandes',
    'demande.info': 'Demande Info',
    'demande.etatDemandeHistListe': 'Etat Demande Hists',
    'civilite.liste': 'Civilites',
    'civilite.info': 'Civilite Info',
    'typeDemandeur.liste': 'Type Demandeurs',
    'typeDemandeur.info': 'Type Demandeur Info',
    'resultatDemande.liste': 'Resultat Demandes',
    'resultatDemande.info': 'Resultat Demande Info',
    'natureSinistre.liste': 'Nature Sinistres',
    'natureSinistre.info': 'Nature Sinistre Info',
    'demandeur.liste': 'Demandeurs',
    'demandeur.info': 'Demandeur Info',
    'actualite.liste': 'Actualites',
    'actualite.info': 'Actualite Info',
    'ville.liste': 'Villes',
    'ville.info': 'Ville Info',
    ancienMotDePasse: 'Ancien mot de passe',
    nouveauMotDePasse: 'Nouveau mot de passe',
    nouveauMotDePasseConfirme: 'Confirmer Nouveau mot de passe',
    '2022': '2022',
    '2023': '2023',
    '2024': '2024',
    '2025': '2025',
    '2026': '2026',
    '2027': '2027',
    enCoursAcaps: 'En Cours Acaps',
    enCoursAutreJuridiction: 'En Cours Autre Juridiction',
};

const actions = {
    accueil: 'Accueil',
    logout: 'Déconnexion',
    annuler: 'Annuler',
    editer: 'Editer',
    ajouter: 'Ajouter',
    retour: 'Retour',
    filtrer: 'Filtrer',
    enregistrer: 'Enregistrer',
    retourListe: 'Retour à la Liste',
    supprimer: 'Supprimer',
    reinitialiser: 'Réinitialiser',
    valider: 'Valider',
    etendre: 'Etendre',
    reduire: 'Réduire',
    enCoursEtude: 'En Cours d’étude',
    besoinComplement: 'Besoin de Complément',
    enAttenteTraitement: 'En Attente de Traitement',
    enCoursTraitement: 'En cours de Traitement',
    cloturee: 'Cloturée',
    'liste.demande': 'Lister Demandes',
    changerMotDePasse: 'Changer mot de passe',
    chercherDemande: 'Chercher Demande',
    completerInformation: 'Completer Informations',
    commenterCloture: 'Envoyer Commentaire',
};

const titres = {
    accueil: 'Accueil',
    historiqueEtat: 'Historique Etats',
    listeFichierJoint: 'Liste Fichiers Joints',
    'identification.assurance': ' Identification de la société d’assurance concernée par la demande',
    'identification.demandeur': ' Identification du demandeur de la médiation',
    'identification.litige': ' Identification du litige',
    'identification.autorisation': 'Autorisation du Client Déliant l’Etablissement d’assurance du Secret Professionnel',
    'user.liste': 'Users',
    user: 'User',
    'etatDemandeHist.liste': 'Etat Demande Hists',
    etatDemandeHist: 'Etat Demande Hist',
    'alerte.liste': 'Alertes',
    alerte: 'Alerte',
    'media.liste': 'Medias',
    media: 'Media',
    'etatDemande.liste': 'Etat Demandes',
    etatDemande: 'Etat Demande',
    'objetLitige.liste': 'Objet Litiges',
    objetLitige: 'Objet Litige',
    'natureLitige.liste': 'Nature Litiges',
    natureLitige: 'Nature Litige',
    'compagnie.liste': 'Compagnies',
    compagnie: 'Compagnie',
    'branche.liste': 'Branches',
    branche: 'Branche',
    'relance.liste': 'Relances',
    relance: 'Relance',
    'demande.liste': 'Demandes',
    demande: 'Demande',
    'civilite.liste': 'Civilites',
    civilite: 'Civilite',
    'typeDemandeur.liste': 'Type Demandeurs',
    typeDemandeur: 'Type Demandeur',
    'resultatDemande.liste': 'Resultat Demandes',
    resultatDemande: 'Resultat Demande',
    'natureSinistre.liste': 'Nature Sinistres',
    natureSinistre: 'Nature Sinistre',
    'demandeur.liste': 'Demandeurs',
    demandeur: 'Demandeur',
    'actualite.liste': 'Actualites',
    actualite: 'Actualite',
    'ville.liste': 'Villes',
    ville: 'Ville',
    nombreParMoisAnneeEnCours: 'Nombre Demandes Par Mois Année en Cours',
    nombreParAnnee: 'Nombre Demandes Par Année',
    nombreParCompagnie: 'Nombre Demandes par Compagnie',
    nombreParEtat: 'Nombre Demandes par Etat',
    nombreParEtatAnneeEnCours: 'Nombre Demandes par Etat Année en Cours',
    nombreParResultatCloture: 'Nombre Demandes par Résultat Cloture ',
    nombreParResultatClotureAnneeEnCours: 'Nombre Demandes par Résultat Cloture Année en Cours',
    nombreParCompagnieParAnnee: 'Nombre Demandes Par Compagnie Par Année',
    nombreParEtatParAnnee: 'Nombre Demandes Par Etat Par Année',
    nombreParResultatClotureParAnnee: 'Nombre Demandes Par Résultat Cloture Par Année',
};

const get = (key: string): string => {
    let filtredKey = key;
    if (_.isNil(key)) {
        return '[]';
    } else if (key === 'libelle') {
        return 'Libelle';
    } else if (key === 'code') {
        return 'Code';
    } else if (key.indexOf('libelle') === 0) {
        filtredKey = key.substring(7);
        filtredKey = filtredKey.charAt(0).toLowerCase() + filtredKey.slice(1);
    }
    let label = map[filtredKey];
    if (!label) {
        label = '[' + filtredKey + ']';
    }
    return label;
};

const action = (key: string): string => {
    if (_.isNil(key)) {
        return '[]';
    }
    let label = actions[key];
    if (!label && key.indexOf('.') > 0) {
        label = actions[key.substring(0, key.indexOf('.'))];
    }
    if (!label) {
        label = '[' + key + ']';
    }
    return label;
};

const titre = (key: string): string => {
    if (_.isNil(key)) {
        return '[]';
    }
    let label = titres[key];
    if (!label) {
        label = '[' + key + ']';
    }
    return label;
};

const labels = {
    get,
    action,
    titre,
};

export default labels;
