import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { serviceOptions } from 'util/notificationMiddleWare';
import { IEntity, IEntityFatherAndMother, IEntityList, IEntityParent } from './crud.modele';

const get = createAsyncThunk(
    'entity/get',
    async (entity: IEntity) => {
        const entityReturned: IEntity = (await axios.get<IEntity>(`api/pbl/${entity._type}/${entity.id}`)).data;
        return entityReturned;
    },
    serviceOptions
);

const list = createAsyncThunk(
    'entity/list',
    async (type: string) => {
        const entityList: IEntity[] = (await axios.get<IEntity[]>(`api/pbl/${type}?cacheBuster=${new Date().getTime()}`)).data;
        return { list: entityList, type: type } as IEntityList;
    },
    serviceOptions
);

const listByParent = createAsyncThunk(
    'entity/list',
    async (parent: IEntityParent) => {
        const entityList: IEntity[] = (await axios.get<IEntity[]>(`api/pbl/${parent.childType}/${parent._type}/${parent.id}?cacheBuster=${new Date().getTime()}`)).data;
        return { list: entityList, type: parent.childType } as IEntityList;
    },
    serviceOptions
);

const listByFatherAndMother = createAsyncThunk(
    'entity/list',
    async (parent: IEntityFatherAndMother) => {
        const entityList: IEntity[] = (await axios.get<IEntity[]>(`api/pbl/${parent.childType}/${parent.father._type}/${parent.father.id}/${parent.mother._type}/${parent.mother.id}?cacheBuster=${new Date().getTime()}`)).data;
        return { list: entityList, type: parent.childType } as IEntityList;
    },
    serviceOptions
);

const createPublic = createAsyncThunk(
    'entity/create',
    async (entity: IEntity) => {
        const entityCreated: IEntity = (await axios.post<IEntity>(`api/pbl/${entity._type}`, entity)).data;
        return entityCreated;
    },
    serviceOptions
);

const create = createAsyncThunk(
    'entity/create',
    async (entity: IEntity) => {
        const entityCreated: IEntity = (await axios.post<IEntity>(`api/prv/${entity._type}`, entity)).data;
        return entityCreated;
    },
    serviceOptions
);

const update = createAsyncThunk(
    'entity/update',
    async (entity: IEntity) => {
        const entityUpdated: IEntity = (await axios.put<IEntity>(`api/prv/${entity._type}`, entity)).data;
        return entityUpdated;
    },
    serviceOptions
);

const del = createAsyncThunk(
    'entity/delete',
    async (entity: IEntity) => {
        await axios.delete(`api/prv/${entity._type}/${entity.id}`);
        return entity;
    },
    serviceOptions
);

const CrudService = {
    get,
    list,
    listByParent,
    listByFatherAndMother,
    create,
    update,
    del,
    createPublic,
};

export default CrudService;
