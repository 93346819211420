import { Button, Menu } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import brand from 'assets/images/brand.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const LayoutHeader = () => {
    const navigate = useNavigate();
    const location = useLocation(); // Get the current location
    const items = [
        { key: 'home', label: <Link to="">ACCUEIL</Link> },
        { key: 'mediateur', label: <Link to="/mediateur">LE MEDIATEUR</Link> },
        {
            key: 'mediation',
            label: <Link to="/mediation">LA MEDIATION</Link>,
            children: [
                { key: 'charte', label: <Link to="/charte">LA CHARTE</Link> },
                {
                    key: 'processus',
                    label: <Link to="/processus">LE PROCESSUSS</Link>,
                },
                {
                    key: 'acteurs',
                    label: <Link to="/acteurs">LES ACTEURS</Link>,
                },
            ],
        },
        {
            key: 'demande',
            label: 'DEMANDE DE MEDIATION',
            children: [
                {
                    key: 'litiges',
                    label: <Link to="/litiges">LITIGES TRAITES</Link>,
                },
                {
                    key: 'quand',
                    label: <Link to="/quand">QUAND SAISIR LE MEDIATEUR</Link>,
                },
                {
                    key: 'comment',
                    label: <Link to="/comment">COMMENT SAISIR LE MEDIATEUR</Link>,
                },
            ],
        },
        { key: 'contact', label: <Link to="/contact">CONTACT</Link> },
    ];

    const isRenvoiPage = location.pathname === '/demande/suivi/renvoi';

    return (
        <Header
            style={{
                background: 'white',
                zIndex: 1,
                width: '100%',
                margin: '0',
                padding: '0',
            }}
        >
            <div style={{ background: 'white' }}>
                <div style={{ width: '1140px', margin: 'auto', height: '70px' }}>
                    <img
                        src={brand}
                        alt="logo"
                        onClick={() => navigate('/')}
                        style={{
                            cursor: 'pointer',
                            height: '70px',
                            position: 'absolute',
                            top: '0',
                        }}
                    />
                </div>
            </div>
            <div style={{ background: '#F6F7FB' }}>
                <div style={{ width: '750px', margin: 'auto' }}>
                    <Menu items={items} mode="horizontal" />
                </div>
            </div>
            <div
                style={{
                    background: 'linear-gradient(16deg, rgba(13,84,97,1) 0%, rgba(0,147,175,1) 100%)',
                    height: '240px',
                }}
            >
                <div
                    style={{
                        width: '1140px',
                        margin: 'auto',
                        padding: '20px',
                        height: '80px',
                    }}
                >
                    <div
                        style={{
                            color: '#CCC',
                            fontSize: '20px',
                            lineHeight: '30px',
                        }}
                    >
                        Vous avez un différent avec votre assureur et le litige persiste?
                    </div>
                    <div
                        style={{
                            color: '#DDD',
                            fontSize: '26px',
                            fontWeight: '700',
                            lineHeight: '40px',
                        }}
                    >
                        Le Médiateur de l&rsquo;Assurance est là{' '}
                    </div>
                    <div
                        style={{
                            color: '#F3F3F3',
                            fontSize: '28px',
                            fontWeight: '700',
                            lineHeight: '40px',
                        }}
                    >
                        Pour Vous Aider
                    </div>
                </div>
                <div
                    style={{
                        width: '1140px',
                        margin: 'auto',
                        textAlign: 'center',
                        height: '50px',
                    }}
                >
                    {!isRenvoiPage ? (
                        <Button
                            style={{
                                margin: '0 10px',
                                height: '50px',
                                fontSize: '24px',
                                borderRadius: '8px',
                            }}
                            type="default"
                            onClick={() => navigate('/demande/saisie')}
                        >
                            Saisir le Médiateur
                        </Button>
                    ) : (
                        <Button
                            style={{
                                margin: '0 10px',
                                height: '50px',
                                fontSize: '24px',
                                borderRadius: '8px',
                            }}
                            type="default"
                            onClick={() => navigate('/')}
                        >
                            Accueil
                        </Button>
                    )}
                    {/* <Button
                        style={{
                            margin: '0 10px',
                            height: '50px',
                            fontSize: '24px',
                            borderRadius: '8px',
                        }}
                        type="default"
                        ghost
                        onClick={() => navigate('/demande/suivi')}
                    >
                        Suivre votre demande
                    </Button> */}
                </div>
            </div>
        </Header>
    );
};

export default LayoutHeader;
