import { Form, Input, Select } from 'antd';
import Vide from 'composants/conteneur/vide';
import ReferentielService from 'core/referentiel/referentiel.service';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import FormulaireValidateur from '../formulaire.validateur';

const ChampReference = (props) => {
    const { Option } = Select;
    const [options, setOptions] = useState([]);
    const [maxLength, setMaxLength] = useState(0);
    const [referentielListe, setReferentielListe] = useState(null);
    const [selectAttributes, setSelectAttributes] = useState(null);
    const { form, attributes, reference, optionLibelle, notifierChangement, adapterLargeur } = props;
    const newValue = Form.useWatch(attributes.name, form);

    useEffect(() => {
        setReferentiel(referentielListe, newValue);
    }, [newValue]);

    useEffect(() => {
        setSelectAttributes({ ...attributes, name: attributes.sname });
        const refOptionList = [];
        if (reference) {
            ReferentielService.listerReferentiel(reference).then((liste) => {
                let max = 0;
                setReferentielListe(liste);
                liste.forEach((r) => {
                    const libelle = optionLibelle ? r[optionLibelle] : r.libelle;
                    max = _.isNil(libelle) ? max : Math.max(max, libelle.length);
                    refOptionList.push(
                        <Option value={r.id} key={r.id}>
                            {libelle}
                        </Option>
                    );
                });
                setOptions(refOptionList);
                setMaxLength(max);
                const referentiel = form.getFieldValue(attributes.name);
                setReferentiel(liste, referentiel);
            });
        }
    }, [reference, optionLibelle]);

    const valueChanged = (a) => {
        setReferentiel(referentielListe, { id: a });
    };

    const valueCleared = () => {
        setReferentiel(referentielListe, {});
    };

    const setReferentiel = (liste, a) => {
        if (liste && liste.length > 0) {
            let referentiel = a?.id ? _.find(liste, { id: a.id }) : {};
            if (_.isNil(referentiel)) {
                referentiel = {};
            }
            if (_.isEmpty(referentiel)) {
                referentiel = { id: null, libelle: null };
            }
            if (_.isArray(attributes.name)) {
                const v = {};
                const d = {};
                d[attributes.name[1]] = referentiel;
                d[attributes.sname[1]] = referentiel?.libelle;
                v[attributes.name[0]] = d;
                form.setFieldsValue(v);
            } else {
                const d = {};
                d[attributes.name] = referentiel;
                d[attributes.sname] = referentiel?.libelle;
                form.setFieldsValue(d);
            }
            if (attributes.onChange) {
                attributes.onChange(referentiel);
            }

            if (notifierChangement) {
                notifierChangement();
            }
        }
    };

    const filter = (input, option) => {
        if (option.children) {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }
        return false;
    };

    const getStyle = () => {
        return adapterLargeur ? { ...attributes.style, width: maxLength + 5 + 'ch' } : { ...attributes.style, width: '100%' };
    };

    const validateur = useContext(FormulaireValidateur);

    const getRules = () => {
        const n = _.isArray(props.attributes.name) ? _.join(props.attributes.name, '.') : props.attributes.name;
        if (props.attributes.requis || (validateur && validateur[n] && validateur[n].requis)) {
            return { required: true, message: props.attributes.label + ' est requis.', whitespace: true };
        }
        return { required: false };
    };

    return (
        <Vide siNul={selectAttributes} style={attributes.style}>
            <Form.Item {...selectAttributes} rules={[getRules]}>
                <Select
                    style={getStyle()}
                    disabled={attributes.disabled}
                    // showSearch
                    optionFilterProp="children"
                    allowClear
                    filterOption={filter}
                    onChange={valueChanged}
                    onClear={valueCleared}
                >
                    {options}
                </Select>
            </Form.Item>
            <Form.Item name={attributes.name} noStyle>
                <Input style={{ display: 'none' }} />
            </Form.Item>
        </Vide>
    );
};

export default ChampReference;
