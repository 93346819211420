import SitePage from './sitepage';
import { SH4 } from './styles';

const CommentPage = () => {
    return (
        <SitePage titre="Comment saisir le médiateur">
            <table>
                <tr>
                    <td style={{ fontWeight: 'bold', paddingRight: '20px' }}>Par voie électronique sur le site</td> <td>www.mediateurassurance.ma</td>
                </tr>
                <tr>
                    <td style={{ fontWeight: 'bold' }}>Par mail sur l&rsquo;adresse</td> <td> mediateur@mediateurassurance.ma</td>
                </tr>
                <tr>
                    <td style={{ fontWeight: 'bold' }}>Par courrier à l&rsquo;adresse</td> <td>LE MEDIATEUR DE L&rsquo;ASSURANCE, 154, Bd d&rsquo;Anfa Casablanca</td>
                </tr>
            </table>
            <div style={{ fontWeight: 'bold', paddingTop: '20px' }}></div>
            <SH4>Pour faciliter la prise en charge de votre demande, votre dossier doit comprendre Impérativement</SH4>
            <ul>
                <li>Les coordonnées de la personne concernée et son adresse mail.</li>
                <li>Le nom de l&rsquo;entreprise d&rsquo;assurance concernée.</li>
                <li>Un résumé du différend et les échanges de courriers avec l&rsquo;assureur relatifs au litige.</li>
                <li>Le courrier de votre assureur indiquant sa position définitive.</li>
                <li>Le numéro de police et la copie du contrat concerné par votre litige.</li>
                <li>Tous les documents que vous jugez utiles pour l&rsquo;étude de votre dossier.</li>
            </ul>
            <p style={{ fontWeight: 'bold' }}>N&rsquo;envoyez pas d&rsquo;originaux, des photocopies ou copies scannées suffisent.</p>
        </SitePage>
    );
};

export default CommentPage;
