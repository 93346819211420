import { Card, Col, Layout, Row } from 'antd';
import { SDashList } from 'domaines/site/styles';

const LayoutFooter = () => {
    const { Footer } = Layout;

    return (
        <Footer>
            <div className="infoWrapper">
                <div className="info">
                    <Row gutter={16}>
                        <Col span={8}>
                            <Card title="ADRESSE" bordered={false}>
                                <div>Le Médiateur de L&rsquo;assurance </div>
                                <div>154 Boulevard d&rsquo;anfa, </div>
                                <div>Casablanca Maroc. </div>
                                <div>Tél : +212 522 649 020 </div>
                                <div>Email : mediateur@mediateurassurance.ma</div>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title="INFORMATIONS" bordered={false}>
                                <SDashList>
                                    <li>Mentions Légales</li>
                                    <li>Liens utiles</li>
                                    <li>Plan d&rsquo;accès</li>
                                    <li>FAQ</li>
                                    <li>Documentation</li>
                                </SDashList>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title="NOUS TROUVER" bordered={false}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3952.3935769418135!2d-7.639146522020841!3d33.590938099107575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7d2ecfc7b93ab%3A0x7b66c202ac893b49!2sFederation%20Marocaine%20Des%20Societes%20d&#39;assurances%20Et%20de%20Reassurance!5e0!3m2!1sfr!2sma!4v1697787659196!5m2!1sfr!2sma" width="300" height="225" allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="copyrightWrapper">
                <div className="copyright">Copyrights &copy; 2022 Le Médiateur de l&rsquo;Assurance</div>
            </div>
        </Footer>
    );
};

export default LayoutFooter;
