import { UploadOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Upload from 'antd/lib/upload/Upload';
import axios from 'axios';
import DialogueConfirmation from 'composants/dialogue/dialogue.confirmation';
import Formulaire, { ChampTexteLong } from 'composants/formulaire';
import { useAppDispatch, useAppSelector } from 'config/store.config';
import CrudService from 'core/session/crud.service';
import { IEtatDemandeHist } from 'modules/etatDemandeHist/etatDemandeHist.modele';
import { useEffect, useState } from 'react';
import { IDemande } from './demande.modele';

const DialogueComplementInformation = ({ visible, setVisible, reload }) => {
    const dispatch = useAppDispatch();
    const demande: IDemande = useAppSelector((state) => state.demandeState.demande);
    const entiteEnregistree = useAppSelector((state) => state.crudState.entiteEnregistree);
    const idEntiteCree = useAppSelector((state) => state.crudState.idEntiteCree);
    const [form] = useForm<IEtatDemandeHist>();
    const [uploadFiles, setUploadFiles] = useState([]);

    useEffect(() => {
        if (!visible) {
            form.resetFields();
            setUploadFiles([]);
        }
    }, [visible]);

    useEffect(() => {
        if (entiteEnregistree) {
            reload();
            fermerDialog();
        }
    }, [entiteEnregistree]);

    const completerInformation = () => {
        form.validateFields().then(() => {
            const nouveauEtatId = demande.dateEtude ? 2 : 1;
            const etatHist = { ...form.getFieldsValue(), etatDemande: { id: nouveauEtatId }, demande, _type: 'etatDemandeHist' };
            dispatch(CrudService.createPublic(etatHist));
        });
    };

    const fermerDialog = () => {
        setVisible(false);
        form.resetFields();
        uploadFiles.forEach((b, index) => {
            const formData = new FormData();
            const d = new Date();
            const fileBlob = new Blob([b.data], { type: b.type });
            if (fileBlob.size > 2000000) {
                notification['error']({ message: 'Le fichier ' + b.libelle + ' n’est pas valide car sa taille dépasse la limite permise de 2Mo.' });
            } else {
                formData.append('file', fileBlob, 'attachement' + index);
                formData.append('libelle', b.libelle);
                formData.append('key', '' + d.getTime() + '' + index);
                formData.append('fileContentType', b.type);
                formData.append('ownerType', 'etatDemandeHist');
                formData.append('ownerId', '' + idEntiteCree);
                axios({
                    method: 'post',
                    url: 'api/pbl/media',
                    data: formData,
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                    .then(function (response) {
                        console.log(response);
                    })
                    .catch(function (response) {
                        console.log(response);
                    });
            }
        });
    };

    const beforeUpload = (file) => {
        const reader = new FileReader();
        reader.onload = () => {
            setUploadFiles([...uploadFiles, { data: reader.result, type: file.type, libelle: file.name }]);
        };
        reader.readAsArrayBuffer(file);
        return false;
    };

    return (
        <DialogueConfirmation titre={'Complément d’informations'} visible={visible} actionAnnuler={fermerDialog} actionConfirmer={completerInformation} nomActionConfirmer="Confirmer">
            <Formulaire form={form}>
                <ChampTexteLong nom="commentaire" requis />
            </Formulaire>
            <div style={{ fontWeight: 'bold', fontSize: '14px', color: 'black', margin: '10px 0 5px 0 ' }}>Ajouter d&rsquo;autres pièces à l’instruction du dossier </div>
            <Formulaire form={form}>
                <Upload beforeUpload={beforeUpload}>
                    <Button icon={<UploadOutlined />}>Joindre un fichier</Button>
                </Upload>
            </Formulaire>
        </DialogueConfirmation>
    );
};

export default DialogueComplementInformation;
