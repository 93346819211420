import SitePage from './sitepage';

const SiteHomePage = () => {
    return (
        <SitePage titre="">
            <div style={{ fontSize: '16px' }}>
                <p>La médiation est un processus amiable de rechercher de solution à un différend par l&rsquo;intermédiaire d&rsquo;un tiers indépendant : le médiateur.</p>
                <p>Le cadre de la médiation institutionnelle en assurances est fixé par la Charte de médiation signée par les entreprises membres de la Fédération Marocaine de l&rsquo;Assurance et l&rsquo;autorité de tutelle l&rsquo;ACAPS.</p>
                <p>Le recours au médiateur de l&rsquo;assurance est gratuit.</p>
                <p>Sont éligibles à l&rsquo;examen du médiateur, les litiges concernant les personnes physiques ou morales et survenus dans le cadre d&rsquo;un contrat d&rsquo;assurance lorsque le montant estimé est supérieur ou égal à 2.000 DH. Ce seuil est porté à 5.000 DH pour les sinistres relevant de l&rsquo;assurance maladie.</p>
                <p>Il convient de préciser que le médiateur n&rsquo;est ni un juge ni un conseiller, sa mission consiste à instaurer une communication entre les parties pour favoriser l&rsquo;émergence d&rsquo;une solution.</p>
                <p>Cependant, si, en dépit de ces efforts, les parties n&rsquo;arrivent pas à s&rsquo;entendre, le médiateur peut emettre un avis tendant à solutionner le litige.</p>
            </div>
        </SitePage>
    );
};

export default SiteHomePage;
