import { Collapse } from 'antd';
import SitePage from './sitepage';

const MediateurPage = () => {
    const { Panel } = Collapse;
    return (
        <SitePage titre="Le Médiateur">
            <Collapse defaultActiveKey={['1']}>
                <Panel header="Mission du Médiateur" key="1">
                    <p>
                        Le Médiateur a pour mission le traitement à
                        l&rsquo;amiable des litiges nés d&rsquo;un contrat
                        d&rsquo;assurance opposant un particulier à une
                        entreprise d&rsquo;assurance.
                    </p>
                </Panel>
                <Panel header="Pouvoirs du Médiateur" key="2">
                    <p>
                        Le Médiateur est responsable de la conduite de sa
                        mission et dispose des moyens et pouvoirs nécessaires
                        pour solliciter des parties tous documents ou
                        renseignements indispensables pour mener à bien sa
                        mission. Le Médiateur peut demander aux parties
                        prenantes de lui communiquer tous documents qu&rsquo;il
                        estime utiles pour l&rsquo;accomplissement de sa
                        mission.
                    </p>
                    <p>
                        {' '}
                        Lorsque le montant du litige ne dépasse pas 50.000 DH,
                        l&rsquo;avis du médiateur s&rsquo;impose à la compagnie
                        d&rsquo;assurances. Dans le cas contraire, il reste
                        loisible à la compagnie d&rsquo;assurances de ne pas
                        suivre l&rsquo;avis du médiateur.
                    </p>
                </Panel>
                <Panel header="Champs de compétence" key="3">
                    <p>
                        Sont éligibles à l&rsquo;examen du Médiateur, les
                        litiges survenus dans le cadre d&rsquo;un contrat
                        d&rsquo;assurances ou d&rsquo;assistance souscrit auprès
                        d&rsquo;une entreprise membre de la FMSAR.{' '}
                    </p>
                    <p>
                        Le Médiateur ne peut être saisi que pour les litiges
                        dont le montant est estimé à 5000 dirhams au moins pour
                        les remboursements maladie et à 2.000 DH pour les autres
                        litiges.{' '}
                    </p>
                    <p>
                        Les litiges peuvent concerner les prestations
                        d&rsquo;assurance, la gestion du contrat et généralement
                        toute contestation survenant dans l&rsquo;application du
                        contrat d&rsquo;assurance ou d&rsquo;assistance.
                    </p>
                </Panel>
            </Collapse>
        </SitePage>
    );
};

export default MediateurPage;
