import { Col, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import ActionPrincipale from 'composants/action/action.principale';
import Panneau from 'composants/conteneur/panneau';
import Etat, { Champ, Tag } from 'composants/etat';
import { ChampTexte } from 'composants/formulaire';
import FormulaireInline from 'composants/formulaire/formulaire.inline';
import Separateur from 'composants/widget/separateur';
import _ from 'config/lodash.config';
import { useAppDispatch, useAppSelector } from 'config/store.config';
import { IDemande } from 'modules/demande/demande.modele';
import DemandeService from 'modules/demande/demande.service';
import { DemandeStore } from 'modules/demande/demande.store';
import DialogueCommenterCloture from 'modules/demande/demande.ui.commenterCloture.dialogue';
import DialogueComplementInformation from 'modules/demande/demande.ui.complementInformation.dialogue';
import ListePieceJointe from 'modules/demande/demande.ui.pieceJointe.liste';
import { IDemandeur } from 'modules/demandeur/demandeur.modele';
import { IEtatDemandeHist } from 'modules/etatDemandeHist/etatDemandeHist.modele';
import EtatDemandeHistService from 'modules/etatDemandeHist/etatDemandeHist.service';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import SitePage from './sitepage';
import { SH4 } from './styles';

const SuivreDemandePage = () => {
    const dispatch = useAppDispatch();
    const demande: IDemande = useAppSelector((state) => state.demandeState.demande);
    const demandeur: IDemandeur = useAppSelector((state) => state.demandeState.demandeur);
    const etatDemandeHist: IEtatDemandeHist = useAppSelector((state) => state.etatDemandeHistState.etatDemandeHist);
    const creationReussie = useAppSelector((state) => state.etatDemandeHistState.creationReussie);
    const [nestPasSinistre, setNestPasSinistre] = useState(false);
    const [dialogueComplementInformationVisible, setDialogueComplementInformationVisible] = useState(false);
    const [dialogueCommenterClotureVisible, setDialogueCommenterClotureVisible] = useState(false);
    const [form] = useForm();
    const { renvoi } = useParams();

    const chercher = () => {
        form.validateFields()
            .then((values) => {
                dispatch(DemandeService.recupererPourSuivi(form.getFieldsValue()));
            })
            .catch((errorInfo) => {
                console.log(form.getFieldsValue());
                console.log(errorInfo);
            });
    };

    const reload = () => {
        console.log('reload', demande);
        dispatch(DemandeService.recupererPourSuivi(demande));
    };

    useEffect(() => {
        if (renvoi !== 'renvoi') {
            dispatch(DemandeStore.initialiser());
            form.resetFields();
        }
    }, []);

    useEffect(() => {
        if (demande) {
            setNestPasSinistre(demande.objetLitige?.libelle?.toUpperCase() !== 'SINISTRE');
            if (demande.demandeur) {
                dispatch(DemandeService.recupererDemandeur('' + demande.demandeur.id));
            }
            if (demande.id) {
                dispatch(EtatDemandeHistService.recupererDernierParDemande(demande.id));
            }
            if (renvoi === 'renvoi') {
                form.setFieldsValue({ ...demande });
            }
        }
    }, [demande]);

    useEffect(() => {
        if (creationReussie) {
            dispatch(DemandeService.recupererPourSuivi(form.getFieldsValue()));
            setDialogueComplementInformationVisible(false);
        }
    }, [creationReussie]);

    const completerInformation = () => {
        setDialogueComplementInformationVisible(true);
    };

    const commenterCloture = () => {
        setDialogueCommenterClotureVisible(true);
    };

    return (
        <SitePage titre="Suivre votre demande de médiation">
            {_.isEmpty(demande) && (
                <div>
                    <div>Veuillez saisir votre nom, prénom et le numéro de votre demande.</div>
                    <Row justify="space-between" align="bottom">
                        <Col span="auto">
                            <FormulaireInline form={form}>
                                <ChampTexte nom="demandeur.libelle" libelle="Nom" requis="true" />
                                <ChampTexte nom="libelle" libelle="Numero Demande" requis="true" />
                            </FormulaireInline>
                        </Col>
                        <Col span="none">
                            <ActionPrincipale nom="chercherDemande" action={chercher} type="remplie" />
                        </Col>
                    </Row>
                </div>
            )}
            {demande?.libelle && (
                <div>
                    <div style={{ marginTop: '40px', padding: '10px', backgroundColor: '#F6F7F9' }}>
                        <div style={{ fontSize: '20px', paddingLeft: '10px' }}>Demande {demande?.libelle} </div>
                        <Etat entite={demande}>
                            <Tag nom="etatDemandeHist.libelle" libelle="Etat Demande" />
                            <Champ nom="dateDemande" />
                        </Etat>
                        {etatDemandeHist && etatDemandeHist.etatDemande?.id === 3 && (
                            <div style={{ padding: '10px' }}>
                                <div style={{ color: 'red' }}>
                                    Votre Dossier est incomplet, veuiller completer les informations selon ce qui suit:
                                </div>
                                <div style={{ whiteSpace: 'break-spaces', padding: '5px 0 10px 0' }}>
                                    {etatDemandeHist.commentaire}
                                </div>
                                <ActionPrincipale nom="completerInformation" action={completerInformation} />
                                <DialogueComplementInformation
                                    visible={dialogueComplementInformationVisible}
                                    setVisible={setDialogueComplementInformationVisible}
                                    reload={reload}
                                />
                            </div>
                        )}
                        {etatDemandeHist && etatDemandeHist.etatDemande?.id === 6 && (
                            <div style={{ padding: '10px' }}>
                                <div>Votre Demande est cloturée avec la conclusion suivante</div>
                                <div style={{ color: 'red', fontWeight: 'bold' }}>{demande?.resultatDemande?.libelle}</div>
                                <div
                                    style={{
                                        whiteSpace: 'break-spaces',
                                        padding: '10px',
                                        margin: '5px 0 20px 0',
                                        border: '1px solid red',
                                    }}
                                >
                                    {etatDemandeHist.commentaire}
                                </div>
                                <div style={{ fontWeight: 'bold' }}>
                                    Voulez-vous envoyer un commentaire en réponse à cette conclusion.
                                </div>
                                <ActionPrincipale nom="commenterCloture" action={commenterCloture} />
                                <DialogueCommenterCloture
                                    visible={dialogueCommenterClotureVisible}
                                    setVisible={setDialogueCommenterClotureVisible}
                                    reload={reload}
                                />
                            </div>
                        )}
                    </div>
                    <Separateur />
                    <Panneau titre="identification.assurance">
                        <Etat entite={demande}>
                            <Champ nom="compagnie.libelle" />
                            <Champ nom="numeroPolice" />
                            <Champ nom="branche.libelle" />
                            <Champ nom="souscripteur" />
                            <Champ nom="assure" />
                            <Champ nom="objetLitige.libelle" />
                        </Etat>
                    </Panneau>
                    <Separateur />
                    <Panneau titre="identification.demandeur">
                        <Etat entite={demandeur}>
                            <Champ nom="typeDemandeur.libelle" />
                            <Champ nom="civilite.libelle" />
                            <Champ nom="libelle" />
                            <Champ nom="prenom" />
                            <Champ nom="adresse" />
                            <Champ nom="ville.libelle" />
                            <Champ nom="telephone" />
                            <Champ nom="email" />
                        </Etat>
                    </Panneau>
                    <Separateur />
                    <Panneau titre="identification.litige">
                        <Etat entite={demande}>
                            <Champ nom="dateSinistre" cache={nestPasSinistre} />
                            <Champ nom="numeroSinistre" cache={nestPasSinistre} />
                            <Champ nom="natureSinistre.libelle" cache={nestPasSinistre} />
                            <Champ nom="montantReclamation" />
                            <Champ nom="descriptionLitige" />
                        </Etat>
                    </Panneau>
                    <Separateur />
                    <Panneau titre="listeFichierJoint">
                        <ListePieceJointe />
                    </Panneau>
                </div>
            )}
            {!demande && <SH4>Veuillez saisir des informations valides</SH4>}
        </SitePage>
    );
};

export default SuivreDemandePage;
