import { Col, Row } from 'antd';
import AfficherPremier from 'composants/conteneur/afficherPremier';
import Panneau from 'composants/conteneur/panneau';
import Etat, { Champ } from 'composants/etat';
import ChampRadio from 'composants/etat/champRadio';
import Separateur from 'composants/widget/separateur';

const EtatDemande = ({ demande, objetLitigeSinistre }) => {
    return (
        <div>
            <Row>
                <Col span="11">
                    <Panneau titre="identification.assurance">
                        <Etat entite={demande}>
                            <Champ nom="compagnie.libelle" />
                            <Champ nom="numeroPolice" />
                            <Champ nom="branche.libelle" />
                            <Champ nom="souscripteur" />
                            <Champ nom="assure" />
                            <Champ nom="objetLitige.libelle" />
                        </Etat>
                    </Panneau>
                </Col>
                <Col span="11" offset={2}>
                    <Panneau titre="identification.demandeur">
                        <Etat entite={demande.demandeur}>
                            <Champ nom="typeDemandeur.libelle" />
                            <Champ nom="civilite.libelle" />
                            <Champ nom="libelle" />
                            <Champ nom="prenom" />
                            <Champ nom="adresse" />
                            <Champ nom="ville.libelle" />
                            <Champ nom="telephone" />
                            <Champ nom="email" />
                        </Etat>
                    </Panneau>
                </Col>
            </Row>
            <Separateur />
            <Row>
                <Col span="11">
                    <Panneau titre="identification.litige">
                        <AfficherPremier si={objetLitigeSinistre}>
                            <Etat entite={demande}>
                                <Champ nom="dateSinistre" />
                                <Champ nom="numeroSinistre" />
                                <Champ nom="natureSinistre.libelle" />
                                <Champ nom="natureLitige.libelle" />
                                <Champ nom="montantReclamation" />
                                <Champ nom="descriptionLitige" />
                            </Etat>
                            <Etat entite={demande}>
                                <Champ nom="montantReclamation" />
                                <Champ nom="descriptionLitige" />
                            </Etat>
                        </AfficherPremier>
                    </Panneau>
                </Col>
                <Col span="11" offset={2}>
                    <Panneau titre="identification.autorisation" libelle="Procèdures en cours">
                        <Etat entite={demande}>
                            <ChampRadio nom="enCoursAcaps" />
                            <ChampRadio nom="enCoursAutreJuridiction" />
                        </Etat>
                    </Panneau>
                </Col>
            </Row>
        </div>
    );
};

export default EtatDemande;
