import { useForm } from 'antd/lib/form/Form';
import DialogueConfirmation from 'composants/dialogue/dialogue.confirmation';
import Formulaire, { ChampTexteLong } from 'composants/formulaire';
import { APP_DATE_FORMAT } from 'config/constants.config';
import { useAppDispatch, useAppSelector } from 'config/store.config';
import { IEtatDemandeHist } from 'modules/etatDemandeHist/etatDemandeHist.modele';
import EtatDemandeHistService from 'modules/etatDemandeHist/etatDemandeHist.service';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { IDemande } from './demande.modele';

const DialogueCommenterCloture = ({ visible, setVisible, reload }) => {
    const dispatch = useAppDispatch();
    const demande: IDemande = useAppSelector((state) => state.demandeState.demande);
    const [form] = useForm<IEtatDemandeHist>();
    const [messageErreur, setMessageErreur] = useState(null);

    useEffect(() => {
        if (!visible) {
            form.resetFields();
            setMessageErreur(null);
        }
    }, [visible]);

    const completerInformation = () => {
        form.validateFields().then(() => {
            const etatHist = { ...form.getFieldsValue(), etatDemande: { id: 7 }, libelle: moment().format(APP_DATE_FORMAT), demande, _type: 'etatDemandeHist' };
            dispatch(EtatDemandeHistService.creer(etatHist));
            reload();
        });
    };

    const fermerDialog = () => {
        setVisible(false);
    };

    return (
        <DialogueConfirmation titre={'Commenter Cloture'} visible={visible} actionAnnuler={fermerDialog} actionConfirmer={completerInformation} nomActionConfirmer="Envoyer">
            <div style={{ color: 'red' }}>{messageErreur}</div>
            <Formulaire form={form}>
                <ChampTexteLong nom="commentaire" requis />
            </Formulaire>
        </DialogueConfirmation>
    );
};

export default DialogueCommenterCloture;
